//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Shpere from "@/components/3d/sphere";
export default {
  props: {
    title: {
      type: String,
      default: "广 告 展 示 厅",
    },
    engtitle: {
      type: String,
      default: "Adverting Exhibition",
    },
    subtitle: {
      type: String,
      default: "上 海 飞 帆 广 告 有 限 公 司",
    },
  },
  components: {
    Shpere,
  },
  data() {
    return {
      action: {
        bgShow: false,
        titleShow: false,
        arrowShow:false,
      },
    };
  },
  mounted() {
    this.action.bgShow = true;
    this.action.titleShow = true;
    this.action.arrowShow = true;
    particlesJS.load("particles", "/particles.json");
  },
  methods: {
    //====================动画
    arrowAnime(el, done) {
      this.anime({
        targets: el,
        translateY:[-40,0],
        opacity: [0, 1],
        easing: "easeOutElastic",
        direction: 'alternate',
        duration: 500,
        loop:true,
        delay: 0,
        complete() {
          done();
        },
      });
    },
    sphereAnime(el, done) {
      this.anime({
        targets: el,
        scale: [0.5, 1],
        opacity: [0, 1],
        easing: "linear",
        duration: 1000,
        delay: 1000,
        complete() {
          done();
        },
      });
    },
    engAnime(el, done) {
      this.anime({
        targets: el,
        translateX: [400, 0],
        opacity: [0, 1],
        easing: "easeOutCirc",
        duration: 800,
        delay: 1000,
        complete() {
          done();
        },
      });
    },
    subTitleAnime(el, done) {
      this.anime({
        targets: el,
        translateX: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutCirc",
        duration: 800,
        delay: 1000,
        complete() {
          done();
        },
      });
    },
    titleAnime(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    //====================动画
  },
};
