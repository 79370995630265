//
//
//

import Main from "./main.vue";
export default {
  components: {
    Main,
  },
  data() {
    return {
      render: true,
    };
  },
  watch: {
    "$route.query.type"(newVal, oldVal) {
      this.render = false;
      let that = this;
      this.$nextTick(() => {
        that.render = true;
      });
      // window.location.reload();
    },
  },
  mounted() {
    console.log(this.$route.query.type);
  },
};
