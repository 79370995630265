//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Footer from "@/components/footer/footer";
import api from "@/api/innovative";
import loginApi from "@/api/login";
import config from "@/config/config";
import HeadLogoBig from "@/components/headLogo/headLogoBig";
import comment from "@/components/comment/comment";
import translate from "@/utils/translate";

export default {
  components: {
    HeadLogoBig,
    Footer,
  },
  computed: {
    getHeight() {
      if (this.$store.state.nav == 0) {
        return "100vh";
      } else {
        return "calc(100vh - 70px)";
      }
    },
  },
  data() {
    return {
      type: null,
      translate: translate,
      list: [],
      currentImgId: null,
      currentBtnLeaveIndex: null,
      isInComment: false,
    };
  },
  mounted() {
    this.refresh();
    //监听鼠标滚动事件
    window.addEventListener(
      "mousewheel",
      this.throttle(this.wheelListener, 250, 500)
    );
  },
  methods: {
    imgRowEnter(el, done) {
      this.anime({
        targets: el,
        opacity: [0, 1],
        translateX: [-400, 0],
        easing: "easeOutCirc",
        duration: 1200,
        delay: 300,
        complete() {
          done();
        },
      });
    },
    imgRowLeave(el, done) {
      this.anime({
        targets: el,
        opacity: [1, 0],
        easing: "easeOutCirc",
        duration: 1200,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    changeCarousel(e, last) {
      if (e != 0) {
        this.$store.commit("setNav", 1);
        this.currentImgId = this.list[e - 1].objectid;
      } else {
        this.$store.commit("setNav", 0);
        this.currentImgId = null;
      }
    },
    wheelListener(e) {
      if (this.isInComment) {
        return;
      }
      if (!e) {
        return;
      }
      if (e.deltaY < 0) {
        this.$refs.carousel.prev();
      } else {
        this.$refs.carousel.next();
      }
    },
    // 简单的节流函数
    throttle(func, wait, mustRun) {
      var timeout,
        startTime = new Date();

      return function () {
        var context = this,
          args = arguments,
          curTime = new Date();

        clearTimeout(timeout);
        // 如果达到了规定的触发时间间隔，触发 handler
        if (curTime - startTime >= mustRun) {
          func.apply(context, args);
          startTime = curTime;
          // 没达到触发间隔，重新设定定时器
        } else {
          timeout = setTimeout(func, wait);
        }
      };
    },
    refresh() {
      let staticUrl = process.env.VUE_APP_STATIC_API;
      let type = this.$route.query.type;
      this.type = type;
      api.getInnovative({ type: this.type }).then((res) => {
        if (res) {
          let tempList = res.map((e) => {
            let tempFileList = e.fileList.map((file) => {
              return {
                ...file,
                // url:config.baseApi + "/file/" + file.type + "/" + file.realName,
                url: staticUrl + "/" + file.type + "/" + file.realName,
              };
            });
            e.fileList = tempFileList;
            return e;
          });
          this.list = tempList;
        }
      });
    },

    maskAnime(el, done) {
      this.anime({
        targets: el,
        opacity: [0, 1],
        easing: "linear",
        duration: 120,
        delay: 0,
        complete() {
          el.isAnime = false;
        },
      });
    },
    btnBoxShow(el, done) {
      this.anime({
        targets: el,
        opacity: [0, 1],
        easing: "linear",
        duration: 120,
        delay: 0,
        complete() {
          el.isAnime = false;
        },
      });
    },
    btnEnter(el) {
      this.anime({
        targets: el,
        translateX: [0, -10],
        scale: [1, 1.5],
        easing: "linear",
        duration: 120,
        delay: 0,
        complete() {},
      });
    },
    btnLeave(el) {
      this.anime({
        targets: el,
        translateX: [-10, -0],
        scale: [1.5, 1],
        easing: "linear",
        duration: 120,
        delay: 0,
        complete() {},
      });
    },
    clickDescribe(item) {
      let that = this;
      this.isInComment = true;
      loginApi.checkLogin().then((res) => {
        that.$layer.iframe({
          scrollbar: false,
          content: {
            content: comment, //传递的组件对象
            parent: that, //当前的vue对象
            data: {
              item: item,
              type: that.type,
            }, //props
          },
          area: ["800px", "600px"],
          title: `${item.name}-评论`,
          cancel: () => {
            that.isInComment = false;
          },
        });
      });
    },
    clickShare() {},
  },
};
