import request from '@/utils/request'
import qs from "qs"
export function getInnovative(data) {
  return request({
    url: 'api/front/innovative'+"?"+qs.stringify(data),
    method: 'get'
  })
}

export default { getInnovative }
